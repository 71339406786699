import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/common/Loading';

import { toast } from 'react-toastify';
import Divider from '../../components/common/Divider';
import BackButtonGifting from '../../components/giftSelection/BackButton';
import CheckoutForm from '../../components/giftSelection/CheckoutForm';
import Header from '../../components/giftSelection/Header';
import Api from '../../constants/api';
import images from '../../constants/images';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setShippingMethod } from '../../redux/selectGiftSlice';
import { CheckoutProp } from '../../types/vouchers';
import CustomNavigate from '../../utils/navigate';
//updated for isForTesting

const Checkout = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const corpState = useAppSelector((state) => state.corpSliceState);
  const orderState = useAppSelector((state) => state.orderState);
  const location = useLocation();

  const recipientId =
    useSearchParams()[0].get('recid') ||
    selectGiftState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });
  const defaultNavigate = useNavigate();

  const state: CheckoutProp = useLocation().state;
  const props = state;
  if (props == null) {
    navigate.to('/hamper/gift-selection/choose-category');
  }
  const { voucher, product } = props;
  if (product == null && voucher == null) {
    navigate.to('/hamper/gift-selection/choose-category');
  }
  const name = product ? product?.name : voucher?.name;
  const image = product ? product?.image_1 : voucher?.image_1;
  const campaignInfo = selectGiftState?.campaignRecipientData?.result?.campaign;
  let pickupAddress;
  if (voucher) {
    pickupAddress = '';
  } else if (campaignInfo && campaignInfo?.isSelfPickupOnly) {
    pickupAddress =
      campaignInfo?.pickupAddress ||
      'Smilie team will reach out to you separately to arrange for pick up.';
  } else {
    pickupAddress =
      product.selfPickupAddress?.trim() ||
      'Smilie team will reach out to you separately to arrange for pick up.';
  }
  const dispatch = useAppDispatch();

  const { address, shippingMethod } = selectGiftState;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedShippingCheckBox, setSelectedShippingCheckBox] =
    useState(false);

  const onCheckBoxClick = useCallback(
    (shippingMethods: ShippingMethodTypes) => {
      if (shippingMethods === 'Self Pick up') {
        setSelectedShippingCheckBox(false);
      } else if (shippingMethods === 'Delivery') {
        setSelectedShippingCheckBox(true);
      }
      dispatch(setShippingMethod(shippingMethods));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!product) return;

    if (
      product?.hasDeliveryOption === true &&
      product?.hasSelfPickupOption === false
    ) {
      setSelectedShippingCheckBox(true);
      dispatch(setShippingMethod('Delivery'));
    } else if (
      product?.hasDeliveryOption === false &&
      product?.hasSelfPickupOption === true
    ) {
      setSelectedShippingCheckBox(false);
      dispatch(setShippingMethod('Self Pick up'));
    }
  }, [dispatch, product]);

  const createOrder = useCallback(
    (values: CreateOrderRecipientDetails) => {
      if (!address) {
        return;
      }
      const orderType: OrderTypesT = product
        ? shippingMethod === 'Delivery'
          ? 'PHYSICAL_DELIVER'
          : 'PHYSICAL_PICKUP'
        : 'DONATION';
      const data: CreateOrder & CreateOrderRecipientDetails = !(product == null)
        ? {
            costInCredits: product!.costPrice,
            costInCurrency: product!.costPrice,
            currency: 'SGD',
            shippingMethod: shippingMethod ?? '',
            url_id: recipientId!,
            productId: product!.id,
            quantity: 1,
            orderType,
            ...values,
          }
        : {
            costInCredits: Math.max(...voucher.denominations),
            costInCurrency: Math.max(...voucher.denominations),
            currency: 'SGD',
            url_id: recipientId!,
            voucherId: voucher?.id,
            voucherDenomination: Math.max(...voucher.denominations),
            shippingMethod: '',
            quantity: 1, // single gift selection, hence quantity is 1
            ...values,
          };
      setIsLoading(true);
      if (selectGiftState.campaignRecipientData?.result.isForTesting) {
        const payload = {
          id: 'testinId',
          deliveryDate: new Date(),
          note: '',
          status: { id: 'teststatus', name: 'Pending' },
          is_draft: false,
          packagingPreference: null,
          orders: [{ ...data, product: selectGiftState.product }],
          shippingMethod: shippingMethod,
          campaign: selectGiftState,
          recipient: selectGiftState.campaignRecipientData,
          ...values,
          createdAt: new Date(),
          updatedAt: new Date(),
          voucherOrders: [],
        };
        console.log(payload);
        defaultNavigate('/gift-selection/thank-you', { state: payload });
        return;
      }
      Api.createOrder(data)
        .then(() => {
          setIsLoading(false);
          setTimeout(() => {
            setIsLoading(false);
            navigate.to('/gift-selection/thank-you');
          }, 500);
        })
        .catch(() => {
          setIsLoading(false);
          toast.error('Error occurred!');
        });
    },
    [
      address,
      navigate,
      product,
      voucher,
      recipientId,
      selectGiftState.campaignRecipientData?.result.isForTesting,
      shippingMethod,
    ]
  );

  // if order found route to thankyou
  useEffect(() => {
    if (orderState.data) {
      navigate.corpId = corpState.data?.template || 'corp';
      navigate.to('/gift-selection/thank-you');
    }
  }, [corpState.data, orderState.data]);

  const logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ''
      ? corpState.data.logo
      : undefined;

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  useEffect(() => {
    if (selectGiftState.campaignRecipientData?.result.giftClaimed) {
      navigate.to('/gift-selection/thank-you-notes');
    }
  }, [
    location,
    navigate,
    selectGiftState.campaignRecipientData?.result.giftClaimed,
  ]);

  return (
    <div>
      <Header
        title="Checkout"
        navigate={navigate}
        logo={logo}
        isPreview={isDeactivated}
      />
      <div className="m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      <div className="grid md:grid-cols-5 max-md:grid-cols-3 md:gap-6 max-md:gap-4 md:mt-0 max-md:mt-5 md:mx-[11%] max-md:mx-5">
        {/* col 1 */}
        <div className="max-sm:px-0 px-12 pb-6 col-span-3">
          {!campaignInfo?.isSelfPickupOnly && (
            <>
              <div className="m-2">
                <div className="font-leagueSpartan-500 text-[24px] text-neutrals-900">
                  Choose one option
                </div>
                <div className="mt-4">
                  {product && product?.hasSelfPickupOption && (
                    <div
                      className="my-3 flex items-center cursor-pointer"
                      onClick={() => onCheckBoxClick('Self Pick up')}
                    >
                      <img
                        src={
                          selectedShippingCheckBox === false
                            ? images.radioActive
                            : images.radioInactive
                        }
                        className="h-[20px] min-w-[20px]"
                        alt=""
                      />
                      <div className="font-leagueSpartan-400 text-[16px] text-neutrals-900  mx-3">
                        Self Pick up
                      </div>
                    </div>
                  )}
                  {product?.hasDeliveryOption && (
                    <div
                      className="my-3 flex items-center cursor-pointer"
                      onClick={() => onCheckBoxClick('Delivery')}
                    >
                      <img
                        src={
                          selectedShippingCheckBox === true
                            ? images.radioActive
                            : images.radioInactive
                        }
                        className="h-[20px] min-w-[20px]"
                        alt=""
                      />
                      <div className="font-leagueSpartan-400 text-[16px] text-neutrals-900  mx-3">
                        Delivery
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <>
            {!campaignInfo?.isSelfPickupOnly && <Divider />}
            <div className="mt-8">
              <CheckoutForm
                createOrder={createOrder}
                isDelivery={selectedShippingCheckBox}
                isVoucher={!(voucher == null)}
                pickupAddress={pickupAddress}
                isSelfPickupOnly={campaignInfo?.isSelfPickupOnly}
              />
            </div>
          </>
        </div>
        {/* col 2 */}
        <div className="col-span-2 max-md:col-span-3">
          <div className="p-6 border rounded-2xl">
            <div className="font-leagueSpartan-500 text-[24px] text-neutrals-900">
              Gift Summary
            </div>

            <div className="grid grid-cols-4 gap-4 my-4">
              <div>
                <img src={image} className="aspect-square rounded-2xl" alt="" />
              </div>
              <div className="col-span-3">
                <p>{name}</p>
              </div>
            </div>

            <div
              className="font-leagueSpartan-300 text-[16ps] tracking-[1.6px] text-orange border-b border-orange w-fit cursor-pointer"
              onClick={() => navigate.to('/gift-selection/choose-category')}
            >
              Change Option
            </div>

            {product && (
              <div className="mt-8 text-neutrals-900">
                <div className="my-4 flex justify-between">
                  <div>Shipping</div> <div className="float-right">Free</div>
                </div>
              </div>
            )}
            {!campaignInfo?.isSelfPickupOnly && (
              <p className="text-orange">
                ** We will try our best to have our delivery partners meet the
                timing **
              </p>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  );
};

export default Checkout;
